import axios from 'axios';

const createAlertApiClient = async (user) => {
  const config = { baseURL: `${process.env.REACT_APP_GITHOPE_API}/apiv3` };
  // Add auth token in prod
  if (process.env.NODE_ENV === 'production') {
    config.headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${await user.getIdToken()}`,
    };
  }

  const http = axios.create(config);

  return {
    getPage: (params) => http.get('/alerts', { params }),
    getAll: () => http.get('/alerts'),
    get: (id) => http.get(`/alerts/${id}`),
    create: (data) => http.post('/alerts', data),
    update: (id, data) => http.put(`/alerts/${id}`, data),
    delete: (id) => http.delete(`/alerts/${id}`),
    vote: (id, vote) => http.post(`/alerts/${id}/votes`, { voteStr: vote }),
  };
};

export default createAlertApiClient;
