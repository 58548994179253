import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

const Tags = (props) => props.tags.map((tag) => (
  <span key={tag} style={{ display: 'inline-block' }}>
    <Tag
      key={tag}
      closable
      onClose={(e) => {
        e.preventDefault();
        props.onClose(tag);
      }}
    >
      {tag}
    </Tag>
  </span>
));

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default Tags;
