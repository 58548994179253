import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';

// Firebase stuff
import withFirebaseAuth from 'react-with-firebase-auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// Antd
import {
  Layout,
  Spin,
} from 'antd';

// Pages
import PlotPage from './PlotPage';
import GitHopeStarAlertPage from './StarAlert';
import NavBar from './NavBar';

// Configs and CSS
import firebaseConfig from './firebaseConfig';
import './App.css';
import 'antd/dist/antd.css';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const firebaseAppAuth = firebase.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful.
  // Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/alerts',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
};

const {
  Header, Footer, Content,
} = Layout;


const PrivateRoute = ({ component: Component, user, ...rest }) => {
  // Disable auth in development by setting a fake user
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-param-reassign
    user = { name: 'dev_user' };
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user === undefined) {
          return <Spin size="large" style={{ display: 'flex', justifyContent: 'center' }} />;
        }
        if (user !== null) {
          return <Component user={user} {...props} />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  user: PropTypes.object,
};


const App = ({ user, signOut }) => (
  <Router>
    <Layout className="layout">
      <Header>
        <h1 className="logo" style={{ float: 'left', marginRight: '2em' }}>
          <a href="/" style={{ color: 'inherit' }}>GitHope</a>
        </h1>
        <NavBar user={user} signOut={signOut} />
      </Header>
      <Content>
        <div className="site-layout-content" style={{ minHeight: 'calc(100vh - 140px)' }}>
          <Switch>
            <PrivateRoute path="/alerts" user={user} component={GitHopeStarAlertPage} />
            <Route path="/login">
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAppAuth} />
            </Route>
            <Route path="/">
              <PlotPage analytics={analytics} />
            </Route>
          </Switch>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>GitHope ©2020 Created by Fly Ventures</Footer>
    </Layout>
  </Router>
);


App.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

// Wrap App with using Firebase Auth
// export default App;
export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
