const firebaseConfig = {
  apiKey: 'AIzaSyBo0LX2t0z063CIBWbdwmNiNKvELJ-B8Tk',
  authDomain: 'auth.githope.com',
  databaseURL: 'https://osstracker-3177a.firebaseio.com',
  projectId: 'osstracker-3177a',
  storageBucket: 'osstracker-3177a.appspot.com',
  messagingSenderId: '69191093523',
  appId: '1:69191093523:web:1b91248c9fdc6f24badee6',
  measurementId: 'G-Q53NLL6VSS',
};

export default firebaseConfig;
