import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Antd
import {
  Row,
  Col,
  Input,
  message,
  Spin,
  Tabs,
} from 'antd';

// Helpers
import useWindowSize from './Helpers';

// Local components
import GitHopePlot from './Plot';
import Tags from './Tags';

// CSS
import 'antd/dist/antd.css';

const { Search } = Input;

const PlotPage = (props) => {
  const { analytics } = props;
  const hash = (window.location.hash || '#').slice(1);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [repos, setRepos] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [activeTab, setActiveTab] = useState('stars');

  const sortXY = (xy) => {
    const [x, y] = [[], []];
    if (xy) {
      const l = [...Object.entries(xy)].sort();
      l.forEach((v) => { x.push(v[0]); y.push(v[1]); });
    }
    return { x, y };
  };

  const lookupStarhistory = async (owner, repo) => {
    let sp = (new URL(document.location)).searchParams;
    let version = "v3";
    if (sp.has("v")) {
      version = sp.get("v");
    }
    const ownerApiUrl = `${process.env.REACT_APP_GITHOPE_API}/api${version}/repo/${owner}`;

    let ret;
    if (!repo) {
      ret = await fetch(ownerApiUrl);
    } else {
      ret = await fetch(`${ownerApiUrl}/${repo}`);
    }
    return ret.json();
  };

  const addPlotData = (owner, repo, data) => {
    const stars = sortXY(data.stars);
    const issues = sortXY(data.open_issues);
    const forks = sortXY(data.forks);

    setPlotData((oldPlotData) => [...oldPlotData,
      {
        name: data.full_name || data.name || `${owner}/${repo}`,
        owner_name: (data.owner_name || "").toLowerCase(),
        stars,
        issues,
        forks,
      },
    ]);
  };

  const removePlotData = (fullName) => {
    const name = fullName.toLowerCase();
    setPlotData((oldPlotData) => (
      oldPlotData.filter((data) => data.name.toLowerCase() !== name
                                   && data.owner_name.toLowerCase() !== name)
    ));
  };

  const fetchPlotData = async (repoStr) => {
    const [owner, repo] = repoStr.toLowerCase().split('/');
    let success = true;
    if (owner) {
      try {
        const dataArr = await lookupStarhistory(owner, repo);
        if (dataArr.length === 0) {
          return false;
        }

        dataArr.forEach((data) => {
          if (!(data && data.stars)) {
            success = false;
            return;
          }

          addPlotData(owner, repo, data);
        });
      } catch (e) {
        return false;
      }
    }
    return success;
  };

  const addRepo = (repo) => {
    setRepos((oldRepos) => [...oldRepos, repo]);
  };

  const removeRepo = (removedRepo) => {
    setRepos((oldRepos) => oldRepos.filter((repo) => repo !== removedRepo));
    removePlotData(removedRepo);
  };

  const handleSearch = async (input) => {
    const isSearchInput = input === searchInput;
    if (!input) {
      return;
    }
    setLoading(true);
    const queries = input.split(',').map((query) => query.trim());

    await Promise.all(queries.map(async (query) => {
      const queryLowercase = query.toLowerCase();
      if (repos.includes(queryLowercase)) {
        message.info(
          <span>
            <b>{query}</b>
            {' '}
            already added
          </span>,
        );
      } else {
        const success = await fetchPlotData(query);
        if (success) {
          addRepo(queryLowercase);
          if (isSearchInput) {
            setSearchInput('');
          }
        } else {
          const repoOrOwner = query.includes('/') ? 'Repo' : 'Owner';
          const warning = (
            <span>
              {repoOrOwner}
              {' '}
              <b>{query}</b>
              {' '}
              not found
            </span>
          );
          message.warning(warning);
        }
      }

      analytics.logEvent('search', { search_term: searchInput });
    }));
    setLoading(false);
  };

  // On first rendering, execute search if input (from hash)
  useEffect(() => {
    if (hash) {
      handleSearch(hash);
    }
  }, []);

  // Update url hash based on repos
  useEffect(() => {
    window.location.hash = repos.join(',');
  }, [repos]);

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const screenWidth = useWindowSize()[0];
  const plot = <GitHopePlot data={plotData} series={activeTab} />;

  return (
    <div className="plotPage">
      <Row justify="center" gutter={[0, 16]}>
        <Col span={16}>
          <Search
            placeholder="owner/repo,..."
            enterButton="Search"
            size="large"
            value={searchInput}
            onChange={handleChange}
            onSearch={handleSearch}
            loading={loading}
            style={{ width: '100%', marginBottom: '0.5em' }}
          />
          <Tags
            tags={repos}
            onClose={removeRepo}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Spin size="large" spinning={loading} />
      </Row>
      {plotData.length > 0 && (
      <Row justify="center">
        <Col span={screenWidth < 1000 ? 24 : 18}>
          <Tabs onChange={toggle} type="card">
            <Tabs.TabPane tab="Stars" key="stars">
              {plot}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Issues" key="issues">
              {plot}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Forks" key="forks">
              {plot}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      )}
    </div>
  );
};

PlotPage.propTypes = {
  analytics: PropTypes.object.isRequired,
};

export default PlotPage;
