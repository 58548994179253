import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';

import {
  DeleteOutlined,
  GithubOutlined,
  LineChartOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import Emoji from 'react-emoji-render';
import numeral from 'numeral';
import createAlertApiClient from './api/AlertApi';

import useWindowSize from './Helpers';

const AddAlertForm = ({ createAlert }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const toggleDrawer = () => {
    setShowDrawer((old) => !old);
  };

  const onCancel = () => {
    toggleDrawer();
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const success = await createAlert(values);
    if (success) {
      form.resetFields();
      toggleDrawer();
    }
    setLoading(false);
  };

  return (
    <div>
      <Button type="primary" onClick={toggleDrawer}>
        <PlusOutlined />
        {' '}
        Add Alert
      </Button>
      <Drawer
        title="Create a new alert"
        width={360}
        onClose={toggleDrawer}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={(
          <div style={{ textAlign: 'right' }}>
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={form.submit} loading={loading} type="primary">
              Save
            </Button>
          </div>
        )}
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onSubmit}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item
                name="repo"
                label="Owner / Repo"
                rules={[{ required: true, message: 'Github Owner/Repository' }]}
              >
                <Input placeholder="Github Owner/Repository" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item
                name="stars"
                label="Star target"
                rules={[{ required: true, message: 'target number of stars' }]}
              >
                <InputNumber min={0} step={100} placeholder="e.g. 2500" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

AddAlertForm.propTypes = {
  createAlert: PropTypes.func.isRequired,
};

const Actions = ({ fullRepoName, deleteAlert, isReadOnly }) => {
  const [loading, setLoading] = useState(false);

  const onDeleteConfirm = async () => {
    setLoading(true);
    const success = await deleteAlert();
    if (!success) {
      // Only in case the deletion failed otheriwse update an unmounted component
      setLoading(false);
    }
  };

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Button
        icon={<LineChartOutlined />}
        href={`/#${fullRepoName}`}
      />
      <Button
        icon={<GithubOutlined />}
        href={`https://github.com/${fullRepoName}`}
        target="_blank"
      />
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={onDeleteConfirm}
      >
        <Button
          type="danger"
          icon={<DeleteOutlined />}
          disabled={isReadOnly}
          loading={loading}
        />
      </Popconfirm>
    </div>
  );
};

Actions.propTypes = {
  fullRepoName: PropTypes.string.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

const NoEntry = ':no_entry_sign:';
const Poop = ':poop:';
const ThumbsUp = ':thumbsup:';
const Rocket = ':rocket:';

const VoteButtons = ({
  votes, recordVote,
}) => {
  // Reverse the map from votes to a list of {userId, timestamp}
  const voteCounts = {
    [NoEntry]: [], [Poop]: [], [ThumbsUp]: [], [Rocket]: [],
  };
  if (votes !== undefined && Object.keys(votes).length > 0) {
    Object.values(votes).forEach(({ created, name, vote_str: voteStr }) => {
      if (!(voteStr in voteCounts)) {
        voteCounts[voteStr] = [];
      }
      voteCounts[voteStr].push({ name, created });
    });
  }

  const voteButtons = Object.entries(voteCounts).map(([vote, users]) => {
    // Sort by created ts for consistency
    const tooltip = users.sort((a, b) => a.created - b.created)
      .map(({ name, created }) => {
        const ts = new Date(created);
        return <p key={name} style={{ margin: 0, textAlign: 'right' }}>{`${name} on ${ts.toLocaleString()}`}</p>;
      });

    return (
      <Popover key={vote} content={tooltip} title="Votes" trigger={tooltip.length > 0 ? 'hover' : 'click'}>
        <Badge count={users.length} offset={[-5, 0]}>
          <Button icon={<Emoji text={vote} />} onClick={() => recordVote(vote)} />
        </Badge>
      </Popover>
    );
  });

  return (
    <Space>
      {voteButtons}
    </Space>
  );
};

VoteButtons.propTypes = {
  recordVote: PropTypes.func.isRequired,
  votes: PropTypes.object.isRequired,
};

const AlertTable = ({
  alerts, loading, deleteAlert, recordVote, onChange,
}) => {
  const columns = [
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 150,
      sorter: (a, b) => a.owner.localeCompare(b.owner),
    },
    {
      title: 'Repo',
      dataIndex: 'repo',
      key: 'repo',
      width: 150,
      sorter: (a, b) => a.repo.localeCompare(b.repo),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
    },
    {
      title: 'Stars',
      dataIndex: 'stars',
      key: 'stars',
      align: 'right',
      width: 75,
      render: (count) => numeral(count).format('0,0'),
      sorter: (a, b) => a.stars - b.stars,
    },
    {
      title: 'Status',
      dataIndex: 'hasTriggered',
      key: 'status',
      align: 'center',
      width: 100,
      render: (hasTriggered) => {
        const color = hasTriggered ? 'green' : 'orange';
        const text = hasTriggered ? 'Triggered' : 'Active';
        return <Tag color={color} style={{ marginRight: 0 }}>{text}</Tag>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'date',
      align: 'center',
      width: 150,
      render: (ts) => (new Date(ts)).toLocaleString(),
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Votes',
      dataindex: 'vote',
      key: 'votes',
      align: 'center',
      width: 150,
      render: (record) => (
        record.hasTriggered
          ? (
            <VoteButtons
              votes={record.votes}
              recordVote={(voteStr) => recordVote(record.id, voteStr)}
            />
          ) : null
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <Actions
          fullRepoName={record.repoStr}
          deleteAlert={() => deleteAlert(record.id)}
          isReadOnly={Boolean(record.hasTriggered)}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={alerts}
      columns={columns}
      loading={loading}
      pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50', '100'] }}
      rowKey="id"
      size="small"
      scroll={{ x: 'max-content' }}
      onChange={onChange}
    />
  );
};

AlertTable.propTypes = {
  alerts: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  deleteAlert: PropTypes.func.isRequired,
  recordVote: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

AlertTable.defaultProps = {
  onChange: () => {},
};

const GitHopeStarAlertPage = ({ user }) => {
  const [alerts, setAlerts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('todo');
  const [loading, setLoading] = useState(false);
  const [alertApi, setAlertApi] = useState();

  const doc2Alert = (doc) => {
    const {
      repo_str: repoStr,
      repo_description: description,
      stars,
      has_triggered: hasTriggered,
      votes,
      created,
      triggered,
    } = doc.data;
    const [owner, repo] = repoStr.split('/');
    // Make sure votes is set because can be undefined
    return {
      id: doc.id,
      repoStr,
      owner,
      repo,
      description,
      stars,
      hasTriggered,
      votes: votes || {},
      created,
      triggered,
    };
  };

  const createAlert = ({ repo, stars }) => {
    const alert = { repo_str: repo, stars };
    return alertApi.create({ alert })
      .then(({ data }) => {
        const newAlert = doc2Alert({ id: data.id, data: data.created || data.data });
        setAlerts((oldAlerts) => [newAlert, ...oldAlerts]);
        message.success('New alert created');
        return true;
      })
      .catch((error) => {
        message.error(error.response.data || 'Error creating alert');
        return false;
      });
  };

  const deleteAlert = (alertId) => alertApi.delete(alertId)
    .then(() => {
      setAlerts((oldAlerts) => oldAlerts.filter(({ id }) => id !== alertId));
      message.success('Alert deleted');
      return true;
    })
    .catch((error) => {
      message.error(error.response.data || 'Error deleting alert');
      return false;
    });

  const recordVote = (alertId, voteStr) => alertApi.vote(alertId, voteStr)
    .then(({ data: recordedVote }) => {
      const targetAlertIdx = alerts.findIndex(({ id }) => id === alertId);
      const newAlerts = [...alerts];
      const targetAlert = newAlerts[targetAlertIdx];
      targetAlert.votes[recordedVote.uid] = recordedVote;
      setAlerts(newAlerts);
    });

  useEffect(() => {
    if (user) {
      const initAlerApi = async () => {
        const api = await createAlertApiClient(user);
        setAlertApi(api);
      };
      initAlerApi();
    }
  }, []); // needs to be empty otherwise it triggers twice with [user]

  const getAlerts = async () => {
    setLoading(true);
    const { data } = await alertApi.getAll();
    setAlerts(data.alerts.map(doc2Alert));
    setLoading(false);
  };

  useEffect(() => {
    if (alertApi !== undefined) {
      getAlerts();
    }
  }, [alertApi]);

  const filterAlerts = (status) => alerts.filter(
    ({ hasTriggered, triggered, votes }) => {
      const thumbUp = () => (hasTriggered === true
           && Object.values(votes).some((v) => v.vote_str === ThumbsUp)
            && Object.values(votes).every((v) => v.vote_str !== NoEntry && v.vote_str !== Rocket));

      const unvoted = () => hasTriggered === true && Object.keys(votes).length === 0;

      switch (status) {
        case 'todo':
          return thumbUp() || unvoted();
        case 'thumb_up':
          return thumbUp();
        case 'unvoted':
          return unvoted();
        case 'custom':
          return hasTriggered === false || triggered;
        case 'triggered':
          return hasTriggered === true;
        case 'all':
          return true;
        case 'rocket':
          return Object.values(votes).some((v) => v.vote_str === Rocket)
        default:
          throw new Error('unimplemented filter');
      }
    },
  );

  const onTabChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const screenWidth = useWindowSize()[0];

  const RadioCounter = ({ value, children }) => (
    <Badge count={filterAlerts(value).length} overflowCount={10000}>
      <Radio.Button value={value}>{children}</Radio.Button>
    </Badge>
  );

  RadioCounter.propTypes = {
    value: PropTypes.number.isRequired,
    children: PropTypes.element.isRequired,
  };

  return (
    <Row justify="center" gutter={[0, 16]}>
      <Col span={screenWidth < 1000 ? 24 : 18}>
        <Space direction="vertical" size="middle">
          <AddAlertForm createAlert={createAlert} />
          <Space direction="vertical" />
          <Radio.Group defaultValue={statusFilter} onChange={onTabChange} buttonStyle="solid">
            <Space size="middle">
              <RadioCounter value="todo">To do</RadioCounter>
              <RadioCounter value="thumb_up">Thumb up</RadioCounter>
              <RadioCounter value="unvoted">Unvoted</RadioCounter>
              <RadioCounter value="custom">Custom</RadioCounter>
              <RadioCounter value="triggered">Trending</RadioCounter>
              <RadioCounter value="all">All</RadioCounter>
              <RadioCounter value="rocket">Rocketship!</RadioCounter>
              {/* <Radio.Button value="archive">Archived</Radio.Button> */}
            </Space>
          </Radio.Group>
          <AlertTable
            alerts={filterAlerts(statusFilter)}
            loading={loading}
            deleteAlert={deleteAlert}
            recordVote={recordVote}
          />
        </Space>
      </Col>
    </Row>
  );
};

GitHopeStarAlertPage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default GitHopeStarAlertPage;
