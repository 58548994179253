import React, { useEffect, useState } from 'react';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';

// Antd
import {
  Menu,
} from 'antd';

import {
  AlertOutlined,
  LineChartOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

import './App.css';
import 'antd/dist/antd.css';


const NavBar = ({ user, signOut }) => {
  const [selectedKey, setSelectedKey] = useState();

  const keyFromLocation = () => {
    const path = window.location.pathname;
    switch (path) {
      case '/':
        return ['main'];
      case '/alerts':
        return ['alerts'];
      default:
        return [];
    }
  };

  useEffect(() => {
    setSelectedKey(keyFromLocation);
  }, []);

  const handleSelect = ({ key }) => {
    if (key === 'signOut') {
      // When signing out, select the main menu
      setSelectedKey(['main']);
    } else {
      // Otherwise, just set the selected one
      setSelectedKey([key]);
    }
  };

  return (
    <Menu theme="dark" mode="horizontal" selectedKeys={selectedKey} onSelect={handleSelect}>
      <Menu.Item key="main">
        <LineChartOutlined />
        <Link to="/">Charts</Link>
      </Menu.Item>
      {user && (
      <Menu.Item key="alerts">
        <AlertOutlined />
        <Link to="/alerts">Alerts</Link>
      </Menu.Item>
      )}
      {user && (
      <Menu.Item key="signOut" style={{ float: 'right' }} onClick={signOut}>
        <LogoutOutlined />
        <Link to="/">Sign out</Link>
      </Menu.Item>
      )}
    </Menu>
  );
};

NavBar.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func.isRequired,
};

export default NavBar;
