import React from 'react';
import PropTypes from 'prop-types';

import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';

import useWindowSize from './Helpers';

const Plot = createPlotlyComponent(Plotly);

const GitHopePlot = ({ data, series }) => {
  const [screenWidth, screenHeight] = useWindowSize();

  const getPlotHeight = () => {
    if (screenWidth >= 992 && screenHeight > 700) {
      return '700px';
    } if (screenWidth >= 768 && screenHeight > 600) {
      return '600px';
    } if (screenWidth >= 576 && screenHeight > 400) {
      return '400px';
    }
    return '300px';
  };

  const plots = data.map((p) => (
    {
      x: p[series].x,
      y: p[series].y,
      name: p.name,
    }
  ));

  const plotlyConfig = { responsive: true };
  const plotlyLayout = {
    showlegend: true,
    legend: { orientation: 'h' },
    yaxis: { showexponent: 'all', exponentformat: 'SI' },
    autosize: true,
    margin: {
      l: 60,
      r: 10,
      b: 0,
      t: 0,
      pad: 0,
    },
  };

  if (screenWidth < 1000 && data.length > 2) {
  // Don't show legend on mobile, this messes up rendering when we have more than
  // ~2 repos.
    // plotlyLayout.showlegend = false;
    plotlyConfig.displayModeBar = false;
  }

  // Elements
  return <Plot data={plots} style={{ width: '100%', minHeight: getPlotHeight() }} config={plotlyConfig} layout={plotlyLayout} />;
};

GitHopePlot.propTypes = {
  data: PropTypes.array.isRequired,
  series: PropTypes.string.isRequired,
};

export default GitHopePlot;
